import React from "react"
import {
  Button,
  Container,
  Row,
} from "react-bootstrap"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import TitleLine from "../components/utils/TitleLine"
import "../styles/SignUpPage.css"
import {auth} from "../firebase"
import TextField from "@material-ui/core/TextField";
import dots from "../images/bgs/dots.svg"
import NewAsyncButton from "../components/utils/NewAsyncButton"



class SendResetEmail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      Email: "",
      buttonText: "Send Password Reset Email",
      buttonColor: "outline-primary",
    }
    this.sendResetEmail = this.sendResetEmail.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  sendResetEmail() {
    
    var email = this.state.Email
    var ref = this
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      this.setState({ buttonColor: "outline-warning" })
      this.setState({ buttonText: "Sending..." })
      auth
        .sendPasswordResetEmail(email)
        .then(function() {
          ref.setState({ buttonText: "Email Sent!" })
          ref.setState({ buttonColor: "outline-success" })
        })
        .catch(function(error) {
          // An error happened.
        })
    } else {
      alert("Invalid Email")
    }
  }
  componentDidMount() {
    

      auth.onAuthStateChanged(user => {
        if (!user) {
          this.setState({ authenticated: false })
        } else {
          this.setState({ authenticated: true })
        }
      })
  }
  handleChange(event) {
    this.setState({ Email: event.target.value })
  }

  render() {
    return (
      <Layout image={dots} full>
        <SEO title="Password Reset" />
        <Container className="topSpacing">
          <TitleLine
            title="Password Reset"
            description="Enter your email below to receive a password reset email. You will only receive the email if your email is registered as an account on RemoteTA."
          />
          <Row
            className="justify-content-center sidesPadding"
            style={{ marginTop: "40px" }}
          >
            <TextField className="mb-3"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              onChange={this.handleChange}
              />
          </Row>
          <Row
            className="justify-content-center sidesPadding"
            style={{ marginTop: "45px" }}
          >
          <NewAsyncButton
            buttonVariants={["primary", "primary", "succcess"]}
            buttonTexts={[
              "Send",
              "Sending...",
              "Sent!",
            ]}
            buttonColor = {[ "","", "", ""]}
            callback={this.sendResetEmail}
          />
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default SendResetEmail
